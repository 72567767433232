<template>
  <div class="screen-profile-edit has-tab-bar">
    <template v-if="participant !== null">
      <app-bar
        :title="participant.firstName + ' ' + participant.lastName"
        :show-back-button="true"
      />
      <div class="container">
        <div class="wrapper-profile-avatar" :class="avatarOrientation">
          <template v-if="participant.avatarUrl === null">
            <div class="no-avatar">
              <round-icon
                :size="140"
                :border-size="0"
                background-color="#E6E6E6"
                icon-class="fal fa-user"
              />
            </div>
          </template>
          <template v-else>
            <div
              class="image-avatar"
              :style="{
                'background-image': 'url(' + participant.avatarUrl + ')'
              }"
            />
          </template>
        </div>

        <div class="form-group">
          <input
            id="event-image"
            type="file"
            class="input-file"
            accept="image/*"
            @change="onFileChange"
          />
          <label
            for="event-image"
            class="btn btn-white-border-green btn-block button-upload-image"
          >
            wijzig foto
          </label>
        </div>

        <h1 class="page-title text-center">Mijn sporten</h1>
      </div>
      <div class="container">
        <div class="form-group">
          <div class="row">
            <div class="col">
              <ul class="activity-list">
                <li
                  v-for="activity in filteredActivityList.slice(
                    0,
                    Math.ceil(activityList.length / 2) - 1
                  )"
                  :key="activity.id"
                  class="activity-list-item"
                  @click.prevent="toggleActivity(activity.id)"
                >
                  <form-checkbox
                    :value="participantActivities.indexOf(activity.id) !== -1"
                    font-size="small"
                    checkbox-size="small"
                  >
                    <div>
                      {{ activity.name }}
                    </div>
                  </form-checkbox>
                </li>
              </ul>
            </div>
            <div class="col">
              <ul class="activity-list">
                <li
                  v-for="activity in filteredActivityList.slice(
                    Math.ceil(activityList.length / 2) - 1
                  )"
                  :key="activity.id"
                  class="activity-list-item"
                  @click.prevent="toggleActivity(activity.id)"
                >
                  <form-checkbox
                    :value="participantActivities.indexOf(activity.id) !== -1"
                    font-size="small"
                    checkbox-size="small"
                  >
                    <div>
                      {{ activity.name }}
                    </div>
                  </form-checkbox>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <hr class="my-3.5 border-gray-300" />

        <form @submit.prevent="updateProfile">
          <div class="form-group">
            <input
              v-model="participant.firstName"
              type="text"
              class="form-control"
              placeholder="Voornaam"
              required
            />
          </div>
          <div class="form-group">
            <input
              v-model="participant.lastName"
              type="text"
              class="form-control"
              placeholder="Achternaam"
              required
            />
          </div>
          <div class="form-group">
            <form-select
              v-model="participant.gender"
              :items="genderChoices"
              empty-text="Geslacht"
              :border-size="2"
              :required="true"
            />
          </div>
          <div class="form-group">
            <form-select
              v-model="participant.yearOfBirth"
              :items="yearOfBirthChoices"
              empty-text="Geboortejaar"
              :border-size="2"
              :required="true"
            />
          </div>
          <!-- Do not show the zip code or phone numbers on Inviplay request -->
          <!--<div class="form-group">
                        <input type="text" class="form-control" placeholder="Postcode" required v-model="participant.postalCode">
                    </div>
                    <div class="form-group">
                        <input type="number" class="form-control" placeholder="Mobiel" v-model="participant.phoneNumber" pattern="[0-9]*" inputmode="numeric">
                    </div>-->
          <div class="form-group">
            <input
              v-model="participant.email"
              type="email"
              class="form-control"
              placeholder="Email"
              required
            />
          </div>
          <div class="form-group">
            <input
              v-model="participant.password"
              autocomplete="off"
              type="password"
              class="form-control"
              placeholder="Verander wachtwoord"
              readonly
              @focus="(e) => e.target.removeAttribute('readonly')"
              @blur="(e) => e.target.setAttribute('readonly', '')"
            />
          </div>
          <div class="form-group">
            <input
              v-model="passwordRepeat"
              type="password"
              class="form-control"
              placeholder="Herhaal wachtwoord"
            />
          </div>

          <div class="form-group">
            <form-checkbox
              v-model="participant.newsletterActivities"
              label="Ik ontvang graag uitnodigingen voor mijn favoriete sportactiviteiten wanneer deze worden aangemaakt"
            />
          </div>

          <div class="form-group">
            <form-checkbox
              v-model="participant.newsletterActivitiesMonthly"
              label="Ik ontvang graag maandelijkse uitnodigingen voor mijn favoriete sportactiviteiten"
            />
          </div>
          <div class="form-group">
            <form-checkbox
              v-model="participant.newsletterActivitiesWeekly"
              label="Ik ontvang graag 2-wekelijkse uitnodigingen voor mijn favoriete sportactiviteiten"
            />
          </div>
          <div class="form-group">
            <form-checkbox
              v-model="participant.newsletterGeneral"
              label="Ik ontvang graag updates via e-mail over nieuwe mogelijkheden op Inviplay (sporadisch)"
            />
          </div>

          <div class="form-group">
            <form-checkbox
              v-model="participant.commentsUpdate"
              label="Ik ontvang graag updates via e-mail wanneer er een prikbord bericht is geplaatst bij een evenement waar ik aan meedoe"
            />
          </div>

          <div class="section-select-range">
            <div class="select-range-header">
              <h1 class="page-title text-center">
                Stuur mij uitnodigingen binnen:
              </h1>
            </div>

            <div class="select-range-wrapper">
              <div class="select-range-km">
                <form-select
                  v-model="participant.rangeChoice"
                  :items="rangeChoices"
                  :border-size="2"
                />
              </div>
              <div />
              <div class="select-range-binder">van</div>

              <div class="select-range-location">
                <input
                  v-model="participant.tempPostalCodeOrCity"
                  type="text"
                  class="form-control"
                  placeholder="stad of postcode"
                  name="tempPostalCodeOrCity"
                />
              </div>
            </div>
          </div>

          <p class="text-center mt-8 mb-6">
            <a
              href="#"
              class="text-red-600 font-semibold"
              @click.prevent="$router.push('/deactivate-account')"
            >
              Deactiveer mijn account
            </a>
          </p>

          <button
            type="submit"
            class="btn btn-green btn-block button-save margin-bottom-15"
          >
            opslaan
          </button>
        </form>
      </div>
    </template>
    <spinner-loader v-else />
    <spinner-loader v-if="participantStatus === 'loading'" />
    <spinner-loader v-if="addressCoordinatesLoading" />

    <tab-bar selected="profile" />
  </div>
</template>
<script>
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import RoundIcon from '@/components/RoundIcon'
import FormCheckbox from '@/components/FormCheckbox'
import FormSelect from '@/components/FormSelect'
import { mapState } from 'vuex'
import { ACTIVITY_LIST } from '../store/actions/activity'
import {
  PARTICIPANT_UPDATE,
  PARTICIPANT_OWN
} from '../store/actions/participant'
import { LOCATION_ADDRESS_COORDINATES } from '../store/actions/location'
import { isValidPostalCode } from '@/utils/helper'
import data from '@/utils/data'
import _ from 'lodash'
import 'simplebar'
import 'simplebar/dist/simplebar.css'
import { getOrientation } from '@/utils/getOrientation'

export default {
  name: 'ProfileEdit',
  components: {
    AppBar,
    TabBar,
    RoundIcon,
    FormSelect,
    FormCheckbox,
    SpinnerLoader
  },
  data() {
    return {
      participant: null,
      avatarOrientation: null,
      participantActivities: [],
      passwordRepeat: null,
      activitiesSet: false,
      genderChoices: data.genderChoices,
      yearOfBirthChoices: data.getYearOfBirthChoices(),
      rangeChoices: data.rangeChoices,
      filteredActivityList: []
    }
  },
  computed: {
    ...mapState({
      activityList: (state) => state.activity.activityList,
      ownProfile: (state) => state.participant.ownProfile,
      participantStatus: (state) => state.participant.status,
      addressCoordinatesLoading: (state) => state.location.status === 'loading'
    }),
    profileAndActivitiesWatchable() {
      return this.ownProfile, this.activityList, Date.now()
    }
  },
  watch: {
    profileAndActivitiesWatchable() {
      if (this.ownProfile && this.activityList) {
        this.setParticipant(this.ownProfile)
      }
    },
    activityList(list) {
      this.filteredActivityList = list.filter((activity) => activity.id !== 39)
    }
  },
  created() {
    this.$store.dispatch(PARTICIPANT_OWN)
    this.$store.dispatch(ACTIVITY_LIST)
  },
  methods: {
    setParticipant(participantObject) {
      // Convert object
      this.participant = { ...participantObject }
      this.participant.yearOfBirth = this.participant.yearOfBirth
        ? this.participant.yearOfBirth.toString()
        : null
      // Set Activities
      for (const property in this.participant.activities) {
        if (!this.participant.activities.hasOwnProperty(property)) continue // eslint-disable-line no-prototype-builtins
        const activtyItem = this.participant.activities[property]
        if (!this.activitiesSet) {
          this.toggleActivity(activtyItem.id)
        }
      }
      this.participant.tempPostalCodeOrCity =
        participantObject.postalCode || participantObject.city
      // Set init boolean
      this.activitiesSet = true
    },
    onFileChange: function (e) {
      // Set upload data
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.participant.newAvatar = files[0]
      const vm = this
      getOrientation(files[0], function (orientation) {
        switch (orientation) {
          case 3:
            vm.avatarOrientation = 'orientation-3'
            break
          case 6:
            vm.avatarOrientation = 'orientation-6'
            break
          case 8:
            vm.avatarOrientation = 'orientation-8'
            break
        }
      })

      // Set image url in view
      const reader = new FileReader()
      reader.onload = (e) => {
        vm.participant.avatarUrl = e.target.result
      }
      reader.readAsDataURL(files[0])
    },
    toggleActivity: function (activityID) {
      // Add or remove item
      if (!_.includes(this.participantActivities, activityID)) {
        this.participantActivities.push(activityID)
      } else {
        const index = this.participantActivities.indexOf(activityID)
        if (index !== -1) {
          this.participantActivities.splice(index, 1)
        }
      }
    },
    updateProfile: async function () {
      if (this.isValid()) {
        const addressResponse = await this.$store.dispatch(
          LOCATION_ADDRESS_COORDINATES,
          this.participant.tempPostalCodeOrCity
        )
        this.participant.coordinates = addressResponse.location
        this.participant.city = addressResponse.city
        if (this.participant.tempPostalCodeOrCity) {
          if (isValidPostalCode(this.participant.tempPostalCodeOrCity)) {
            this.participant.postalCode = this.participant.tempPostalCodeOrCity
            this.participant.city = null
          } else {
            this.participant.city = this.participant.tempPostalCodeOrCity
            this.participant.postalCode = null
          }
        } else {
          this.participant.city = null
          this.participant.postalCode = null
          this.participant.coordinates = null
        }
        this.$store
          .dispatch(PARTICIPANT_UPDATE, {
            participant: this.participant,
            activities: this.participantActivities
          })
          .then(() => {
            this.$router.push('/profile-own')
          })
          .catch((error) => {
            if (error.response !== undefined) {
              alert(
                'Er is iets fout gegegaan bij het opslaan: ' +
                  error.response.data.error.message
              )
            } else if (error !== undefined) {
              alert('Er is iets fout gegegaan bij het opslaan: ' + error)
            }
          })
      }
    },
    passwordIsValid(password) {
      // Check if password contains at least one capital letter and one number
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
      return password.match(re)
    },
    isValid: function () {
      let isValid = true
      let message = null
      if (_.isEmpty(this.participant.firstName)) {
        isValid = false
        message = 'Voornaam is verplicht'
      } else if (_.isEmpty(this.participant.lastName)) {
        isValid = false
        message = 'Achternaam is verplicht'
      } else if (_.isEmpty(this.participant.gender)) {
        isValid = false
        message = 'Geslacht is verplicht'
      } else if (_.isEmpty(this.participant.yearOfBirth)) {
        isValid = false
        message = 'Geboortejaar is verplicht'
      } else if (_.isEmpty(this.participant.email)) {
        isValid = false
        message = 'Password is verplicht'
      } else if (!_.isEmpty(this.participant.password)) {
        if (!this.passwordIsValid(this.participant.password)) {
          isValid = false
          message =
            'De lengte van het wachtwoord moet minstens 8 tekens zijn. Gebruik hoofd- en kleine letters en cijfers.'
        } else if (this.participant.password !== this.passwordRepeat) {
          isValid = false
          message =
            '"Wachtwoord herhalen" moet gelijk zijn aan "wachtwoord". Als je je wachtwoord niet wil wijzigen, laat het wachtwoord veld dan leeg.'
        }
      }
      if (message !== null) {
        alert(message)
      }

      return isValid
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
input[readonly] {
  background-color: white;
}
.wrapper-profile-avatar {
  border: 2px solid $ipGreen;
}
.section-select-range {
  margin-bottom: 30px;
}
.page-title {
  margin: 40px 0 30px 0;
}
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file + label {
  display: block;
}
.activity-list {
  list-style: none;
  padding: 0;
}
.orientation-3 {
  @include transform(rotate(180deg));
}
.orientation-6 {
  @include transform(rotate(90deg));
}
.orientation-8 {
  @include transform(rotate(-90deg));
}
.select-range-header {
  font-size: 1.25rem;
  font-weight: inherit;
  margin: 15px 0;
  text-align: left;
}

.select-range-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-range-wrapper .select-range-km {
  width: 30%;
  display: inline-block;
}

.select-range-wrapper .select-range-location {
  flex-grow: 1;
  display: inline-block;
}

.select-range-wrapper .select-range-binder {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  margin: 10px 0 10px 0;
}

.disclaimer {
  font-size: 0.875rem;
  color: $ipGreyLight;
}

@media all and (max-width: 575px) {
  .select-range-wrapper {
    flex-direction: column;
  }
  .select-range-wrapper .select-range-km {
    width: 100%;
  }

  .select-range-wrapper .select-range-location {
    width: 100%;
  }

  .select-range-wrapper .select-range-binder {
    align-self: flex-start;
  }
}
</style>
